import axios from "axios";
import NProgress from "nprogress";

// const baseDomain = process.env.VUE_APP_DOMAIN;
// const baseURL = process.env.VUE_APP_API;
const baseURL = "http://amni.com/amnibetav3/";

const instance = axios.create({
  baseURL
});

// before a request is made start the nprogress
instance.interceptors.request.use(config => {
  NProgress.start();
  return config;
});

// before a response is returned stop nprogress
instance.interceptors.response.use(
  response => {
    NProgress.done();
    return response;
  },
  function(error) {
    NProgress.done();
    return error.response;
  }
);

export default instance;
